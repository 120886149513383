
  import { Component, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import { VCheckbox, VTextField } from 'vuetify/lib/components'
  import GTextarea from '@/components/core/input/GTextarea.vue'
  import _ from 'lodash'
  import { Process } from '@/entities/settings'
  import { isValidNumber } from '@/utils/general'

@Component({
  components: {
    GTextarea,
    VCheckbox,
    VTextField,
  },
})
  export default class SystemMessage extends GForm {
  // Lista de procesos (cada proceso tiene su config y configMessage)
  process: Process[] = []

  // Índice seleccionado en la lista de la izquierda
  selected = null

  // Búsqueda en la lista de procesos
  search = null

  // Control de paneles abiertos en la vista principal
  panelsOpen = [0]

  // Control de paneles abiertos en el nivel de sub-secciones
  panelsOpenSection = [0]

  // Objeto donde se almacenan el proceso actual y los valores editables
  formData = {
    process: null as any,
    values: {} as Record<string, any>,
  }

  /**
   * Computed: Retorna solo los campos de primer nivel (sin secciones ni mensajería).
   */
  get topLevelFields () {
    const { process } = this.formData
    const fieldsArray = process?.configMessage?.fields || []
    return fieldsArray.filter((field: any) => !field.sections && !field.messaging)
  }

  /**
   * Computed: Retorna las secciones o bloques que sí contienen "sections" o "messaging".
   */
  get sectionFields () {
    const { process } = this.formData
    const fieldsArray = process?.configMessage?.fields || []
    return fieldsArray.filter((field: any) => field.sections || field.messaging)
  }

  /**
   * Computed: Filtra la lista de procesos de acuerdo a la búsqueda actual.
   * Además, sincroniza el índice seleccionado.
   */
  get filteredProcess () {
    if (!this.search?.length) {
      const lastProcess = this.formData.process
      this.selected = this.process.findIndex(p => p.id === lastProcess?.id)
      return this.process
    }
    return this.process.filter((p: Process) =>
      p.description.toLowerCase().includes(this.search?.toLowerCase())
    )
  }

  get placeHolders () {
    const { process } = this.formData

    if (!process?.configMessage?.placeholders) return []

    return Object.entries(process.configMessage.placeholders)
      .map(([key, details]) => {
        // Asegurar que details sea un objeto válido
        if (typeof details === 'object' && details !== null) {
          return {
            key, // Incluye el key como parte del objeto resultante
            ...details, // Solo propaga si details es un objeto válido
          }
        } else {
          console.warn(`El placeholder con key "${key}" no tiene un formato válido.`)
          return { key } // Devuelve solo la clave si el formato es incorrecto
        }
      })
      .sort((a, b) => a.key.localeCompare(b.key))// Ordena por la propiedad 'order'
      .filter(holder => holder?.default || holder?.path?.length)
  }

  get leftPlaceholders () {
    const placeholders = this.placeHolders
    const half = Math.ceil(placeholders.length / 2)
    return placeholders.slice(0, half)
  }

  get rightPlaceholders () {
    const placeholders = this.placeHolders
    const half = Math.ceil(placeholders.length / 2)
    return placeholders.slice(half)
  }

  /**
   * Ciclo de vida: Al montar, busca los procesos con configMessage.
   */
  async mounted () {
    this.process = await this.fetchData({
      query: { name: 'find', model: 'Process', order: { order: 'asc' } },
      filter: { config_message: { _is_null: false } },
      force: true,
    })

    // Selecciona el primero
    this.formData.process = this.process[0]
    this.selected = 0

    // Inicializa los campos si hay un proceso seleccionado
    if (this.formData.process) {
      this.initializeFields()
    }
  }

  /**
   * Carga inicial de los campos en el panel derecho.
   */
  initializeFields () {
    const { process } = this.formData
    if (!process?.configMessage?.fields) return
    this.formData.values = this.initializeRecursive(process.configMessage.fields)
  }

  /**
   * Recorre recursivamente el array de campos para setear sus valores en this.formData.values.
   */
  initializeRecursive (fields: any[]): Record<string, any> {
    const values: Record<string, any> = {}

    fields.forEach((field: any) => {
      this.processField(field, values)
    })

    return values
  }

  /**
   * Procesa un objeto field. Si contiene secciones o mensajes, llama a funciones auxiliares.
   * Si tiene 'key', asigna un valor por defecto si no existe en config.
   */
  processField (field: any, values: Record<string, any>) {
    // 1. Procesar messaging.sections si existen
    if (field.messaging && field.messaging.sections) {
      this.processSections(field.messaging.sections, values)
    }

    // 2. Procesar sections si existen
    if (field.sections) {
      this.processSections(field.sections, values)
    }

    // 3. Procesar array de fields
    if (field.fields && Array.isArray(field.fields)) {
      field.fields.forEach((fieldItem: any) => {
        this.processMsgField(fieldItem, values)
      })
    }

    // 4. Asignar valor por defecto o guardado en config si 'field.key' existe
    if (field.key) {
      values[field.key] =
        this.getValueFromConfig(field.key, this.formData.process.config) ??
        field.default ??
        null
    }
  }

  /**
   * Recorre todas las secciones, que pueden contener messages.
   */
  processSections (sections: Record<string, any>, values: Record<string, any>) {
    Object.values(sections).forEach((section: any) => {
      this.processSection(section, values)
    })
  }

  /**
   * Procesa una sección individual con su array de mensajes.
   */
  processSection (section: any, values: Record<string, any>) {
    if (section.messages && Array.isArray(section.messages)) {
      section.messages.forEach((message: any) => {
        this.processMessage(message, values)
      })
    }
  }

  /**
   * Procesa un mensaje, que contiene fields.
   */
  processMessage (message: any, values: Record<string, any>) {
    if (message.fields && Array.isArray(message.fields)) {
      message.fields.forEach((msgField: any) => {
        this.processMsgField(msgField, values)
      })
    }
  }

  /**
   * Procesa un field dentro de un mensaje.
   */
  processMsgField (msgField: any, values: Record<string, any>) {
    if (msgField.key) {
      values[msgField.key] =
        this.getValueFromConfig(msgField.key, this.formData.process.config) ??
        msgField.default ??
        null
    }
  }

  /**
   * Devuelve propiedades adicionales para el componente en base al tipo.
   */
  getFieldProps (field: any) {
    const props: Record<string, any> = {}
    props.label = field.label || ''
    props.default = field.default || null

    switch (field.component) {
      case 'g-textarea':
        props.rows = 10
        props.counter = 1024
        break
      case 'v-text-field':
        props.outlined = true
        props.dense = true
        break
      case 'v-checkbox':
        props.hideDetails = true
        break
      default:
        break
    }

    return props
  }

  /**
   * Obtiene un valor del config (Process.config) usando la key (posiblemente con notación "a.b.c").
   */
  getValueFromConfig (key: string, config: any): any {
    try {
      if (!key || typeof key !== 'string') {
        throw new Error(`Clave inválida: "${key}"`)
      }
      // Partimos la key en subkeys si viene en formato "a.b.c"
      const keys = key.split(/\.|\[|\]/).filter(k => k)
      return keys.reduce(
        (obj, k) => (obj && obj[k] !== undefined ? obj[k] : undefined),
        config
      )
    } catch (error) {
      console.error(`Error al obtener el valor para la clave "${key}":`, error)
      return undefined
    }
  }

  syncFieldDefault (fields, key, newValue) {
    if (!fields) return
    for (const field of fields) {
      // Si coincide directamente con field.key
      if (field.key === key) {
        field.default = newValue
      }
      // Si el field tiene su propia estructura messaging.sections
      if (field.messaging && field.messaging.sections) {
        Object.values(field.messaging.sections).forEach(section => {
          if (section.messages) {
            section.messages.forEach(msg => {
              if (msg.fields) {
                msg.fields.forEach(msgField => {
                  if (msgField.key === key) {
                    msgField.default = newValue
                  }
                })
              }
            })
          }
        })
      }
      // Si el field anida más fields (por ejemplo field.fields)
      if (field.fields) {
        this.syncFieldDefault(field.fields, key, newValue)
      }
    }
  }

  /**
   * Función que se llama al presionar "Actualizar".
   * Copia los valores editados a un nuevo config y los guarda en la base.
   */
  async send () {
    const newConfig = _.cloneDeep(this.formData.process.configMessage)

    for (const [key, value] of Object.entries(this.formData.values)) {
      // 1. Actualizar en messaging
      _.set(newConfig, key, value)
      // 2. Buscar el field con esa key y asignar default = value
      this.syncFieldDefault(newConfig.fields, key, value)
    }
    console.log(newConfig)
    // Actualiza en la DB
    await this.pushData({
      model: 'Process',
      fields: {
        id: this.formData.process.id,
        config_message: newConfig,
      },
    })

    await this.loadNotification({
      // @ts-ignore
      message: `Proceso actualizado`,
      notificationType: 'success',
    })
  }

  /**
   * Watcher: Cuando cambia el índice seleccionado, actualiza formData.process.
   */
  @Watch('selected', { immediate: true })
  onSelectedChange (val) {
    if (!isValidNumber(val)) {
      this.selected = 0
      this.formData.process = this.process[0]
      return
    }
    this.formData.process = this.filteredProcess[val]
    this.initializeFields()
  }
  }
